import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom.css'
import Login from './components/Login';
import Navbar from "./BiztranControls/Navbar";
import Create_FTTH_Project from './components/Masters/Create_FTTH_Project';

export default class App extends Component {
  static displayName = App.name;

  render () {
      return (
        <BrowserRouter>
          <Switch>
              <Route exact path="/" component={LoginContainer}/>
              <Route component={DefaultContainer}/>
          </Switch>
        </BrowserRouter>
    );
  }
}

const LoginContainer = () => ( 
    <Route path="/" component={Login} /> 
)

const DefaultContainer = () => (
  <div>
    <Navbar/> 
  </div>
 )
