import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import blue from "@material-ui/core/colors/blue";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import global from "../components/Global";

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    width:200,
    height:130
    //alignItems: "flex-end"
  },
  input: {
    display: "none"
  },
  button: {
    color: blue[900],
    margin: 10
  },
});

class ImageUploadCard extends React.Component {
  constructor(props){
    super(props);
    this.handleUploadClick = this.handleUploadClick.bind(this);
    this.imageResetHandler = this.imageResetHandler.bind(this);
    this.state = {
      mainState: "initial", // initial, search, gallery, uploaded
      imageUploaded: 0,
      selectedFile: null, FileNameBase64:'', FileName:'', file: null, base64URL: ""
    }
  }

  handleUploadClick = event => {
    console.log();
    var file = event.target.files[0];
    const reader = new FileReader();
    //var url = reader.readAsDataURL(file);
    debugger;
    this.setState({FileName:file.name});
    reader.onloadend = function(e) {
      this.setState({
        selectedFile: [reader.result]
      });
    }.bind(this);
    //console.log(url); // Would see a path?

    this.setState({
      mainState: "uploaded",
      selectedFile: event.target.files[0],
      imageUploaded: 1
    });

    this.getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        debugger;
        this.setState({
          base64URL: result,
          file
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  renderInitialState() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CardContent>
          <Grid container/*  justify="center" alignItems="center" */>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={this.handleUploadClick}
            />
            <label htmlFor="contained-button-file">
              <Fab component="span" className={classes.button}>
                <AddPhotoAlternateIcon />
              </Fab>
            </label>
          </Grid>
        </CardContent>
      </React.Fragment>
    );
  }

  renderUploadedState() {
    debugger;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CardActionArea onClick={this.imageResetHandler}>
          <img
            width="100%"
            height="100%"
            className={classes.media}
            src={this.state.selectedFile}
            alt="test"
          />
        </CardActionArea>
      </React.Fragment>
    );
  }

  imageResetHandler = event => {
    console.log(event);
    console.log("Click!");
    this.setState({
      mainState: "initial",
      selectedFile: null,
      imageUploaded: 0
    });
  };

  getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };

  SaveImage(){
    if(this.state.FileName !== ""){
      var bodyFormData = new FormData();
      //var UserID = localStorage.getItem("UserID");
      bodyFormData.append('Type',this.props.FileType);
      bodyFormData.append('FileName',this.props.UserID);
      bodyFormData.append('ImageString',this.state.FileName);
      bodyFormData.append('App','CNPT');
      axios({
        url: global.BASE_URL+"User/UploadImage",
        method: 'post',
        headers: {'Content-Type': 'multipart/form-data'},
        data: bodyFormData
      })
      .then((response) => {
        debugger;
        console.log(response);
        alert("Uploaded Successfully.");
      })
      .catch((response) => {
        console.log(response);
      });
    } 
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Card className={this.props.cardName}>
            {((this.state.mainState === "initial" && this.renderInitialState()) ||
              
              (this.state.mainState === "uploaded" &&
                this.renderUploadedState()))}
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ImageUploadCard);
