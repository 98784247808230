import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
//import { DataGrid } from "@material-ui/data-grid";
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from "@material-ui/core/styles";
import global from "../../components/Global";

const theme = createMuiTheme({
  typography: {
    fontSize: 13,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: global.COLOR.Secondary,
      color: "white",
      fontSize: 16,
    },
  },
}));

export default function Data_Grid(props) {
  //debugger;
  const {
    rows,
    columns,
    checkboxSelection,
    hideFooterPagination,
    rowHeight,
    Width,
    Height,
    ...reset
  } = props;
  const classes = useStyles();

  return (
    <div
      style={{ width: Width, height: Height, marginTop: 3, overflow:'hidden', position:'relative' }}
      className={classes.root}
    >
      <ThemeProvider theme={theme}>
        <DataGrid
          rows={rows}
          columns={columns}
          checkboxSelection={checkboxSelection}
          hideFooterPagination={hideFooterPagination}
          headerHeight={34}
          rowHeight={rowHeight}
          {...reset}
          /* onSelectionChange={e => console.log(e.rows)} */
        />
      </ThemeProvider>
    </div>
  );
}
