module.exports = {
   BASE_URL: "http://stl.biztran.in/",
  //BASE_URL: "http://stlcore.biztran.in/",
  // BASE_URL: "http://localhost:5018/",
  UserName: "",
  UserID: "",
  COLOR: {
    Primary: "#1B47C5",
    Secondary: "#e86915",
  },
};
