import React from 'react';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import global from './../Global';
import BiztranContorls_01 from '../../BiztranControls/BiztranControls';
import BiztranControl from '../../BiztranControls_01/BiztranControls';
import axios from 'axios';

let SurveyTempID = '', SurveyTempDtlsID = '', btnDisable = false, btnColor = global.COLOR.Secondary;

const validationSchema = Yup.object({
    QuestionType:Yup.string().required('Required!'),
    Seq:Yup.string().required('Required!'),
    QuestionDesc:Yup.string().required('Required!'),
    IsMandatory:Yup.string().required('Required!')
})

/* const onSubmit = values => { 
    var IsMandatory = '';
    if(values.IsMandatory === true){
        IsMandatory = 'Y';
    }else{
        IsMandatory = 'N';
    }
    var ChoiceOptions = [];
    if(values.ChoiceOptions.length > 0){
        ChoiceOptions = values.ChoiceOptions;
        ChoiceOptions = ChoiceOptions.replaceAll('\n',',')
    }
    var bodyFormData = new FormData();
    bodyFormData.append('SurveyTempDtlsID',SurveyTempDtlsID);
    bodyFormData.append('SurveyTempID',SurveyTempID);
    bodyFormData.append('QuestionType',values.QuestionType);
    bodyFormData.append('Sequence',values.Seq);
    bodyFormData.append('QuestionDesc',values.QuestionDesc);
    bodyFormData.append('GuidanceHint',values.GuidanceHint);
    bodyFormData.append('ChoiceOptions',ChoiceOptions.toString());
    bodyFormData.append('IsMandatory',IsMandatory);
    axios({
        url: global.BASE_URL+"SurveyAPI/InsertSurveyTemplatesDtls",
        method: 'post',
        headers: {'Content-Type': 'multipart/form-data'},
        data: bodyFormData
    })
    .then(function (response) {
        if(response.data.Response === true){
            debugger;
            //this.props.handleClose();
            //btnDisable = true;
            //btnColor = '#dddddd';
            alert("Saved Successfully.");
        }
        else{
            alert("Save Failed.");
        }
    })
    .catch(function (response) {
        console.log(response);
    });  
}  */

const QuetionTypeOptions = [
    { key: 'Single Line Answer', value:'Single Line Answer' },
    { key: 'Multi Line Answer', value:'Multi Line Answer' },
    { key: 'Dropdown', value:'Dropdown' },
    { key: 'Multiple Choice', value:'Multiple Choice' },
    { key: 'Check Boxes', value:'Check Boxes' },
    { key: 'Date Time', value:'Date Time' },
    { key: 'Attachment-Single', value:'Attachment-Single' },
    { key: 'Attachment-Multiple', value:'Attachment-Multiple' }
]

class Add_Question extends React.Component {
    constructor(props){
        super(props);
        this.state={
            QuestionType:'', Seq:'', QuestionDesc:'', GuidanceHint:'', ChoiceOptions:[], IsMandatory:'', display:'flex', Disable:true
        }
    }

    onSubmit = values => { 
        var IsMandatory = '';
        if(values.IsMandatory === true){
            IsMandatory = 'Y';
        }else{
            IsMandatory = 'N';
        }
        var ChoiceOptions = [];
        if(values.ChoiceOptions.length > 0){
            ChoiceOptions = values.ChoiceOptions;
            ChoiceOptions = ChoiceOptions.replaceAll('\n',',')
        }
        var bodyFormData = new FormData();
        bodyFormData.append('SurveyTempDtlsID',SurveyTempDtlsID);
        bodyFormData.append('SurveyTempID',SurveyTempID);
        bodyFormData.append('QuestionType',values.QuestionType);
        bodyFormData.append('Sequence',values.Seq);
        bodyFormData.append('QuestionDesc',values.QuestionDesc);
        bodyFormData.append('GuidanceHint',values.GuidanceHint);
        bodyFormData.append('ChoiceOptions',ChoiceOptions.toString());
        bodyFormData.append('IsMandatory',IsMandatory);
        axios({
            url: global.BASE_URL+"SurveyAPI/InsertSurveyTemplatesDtls",
            method: 'post',
            headers: {'Content-Type': 'multipart/form-data'},
            data: bodyFormData
        })
        .then(function (response) {
            if(response.data.Response === true){
                debugger;
                //this.props.handleClose;
                //btnDisable = true;
                //btnColor = '#dddddd';
                alert("Saved Successfully.");
            }
            else{
                alert("Save Failed.");
            }
        })
        .catch(function (response) {
            console.log(response);
        });  
    }

    QueTypedata() {
        let QueTypeCombo = QuetionTypeOptions.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return QueTypeCombo;
    }

    DeleteTempQtnConfirm(){
        try {
            const confirmBox = window.confirm(
                "Do you really want to delete Qtn?"
              )
              if (confirmBox === true) {
                this.DeleteTempQtn();
            }
        } 
        catch (error) {
           console.log(error); 
        }
    }

    DeleteTempQtn(){
        try {
            debugger;
            var bodyFormData = new FormData();
            bodyFormData.append('SurveyTempDtlsID',SurveyTempDtlsID);
            axios({
                url: global.BASE_URL+"SurveyAPI/DeleteSurveyTemplateQtn",
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data'},
                data: bodyFormData
            })
            .then(function (response) {
                if(response.data.Response === true){
                    debugger;
                    this.props.handleClose();
                    alert("Deleted Successfully.");
                }
                else{
                    alert("Delete Failed.");
                }
            })
            .catch(function (response) {
                console.log(response);
            });
        } 
        catch (error) {
            console.log(error);
        }
    }

    GetQuestionDetails(){
        try {
            SurveyTempDtlsID = this.props.SurveyTempDtlsID;
            var bodyFormData = new FormData();
            bodyFormData.append('TemplateDetailID',SurveyTempDtlsID);
            axios({
                url: global.BASE_URL+"SurveyAPI/GetAddQuesDetails",
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
            .then((response) => {
                var IsMandatory=false;
                if(response.data[0].is_mandatory === "Y"){
                    IsMandatory = true;
                } else{
                    IsMandatory = false;
                }
                if(response.data[0].question_type === "Dropdown" || response.data[0].question_type === "Multiple Choice" || response.data[0].question_type === "Check Boxes" || response.data[0].question_type === "Attachment-Multiple"){
                    this.setState({Disable:false});
                }
                else{
                    this.setState({Disable:true});
                }
                if(response.data[0].choice_options !== null){
                    var ChoiceOption = response.data[0].choice_options
                    /* var CP = ChoiceOption.split(',').map(item => item.trim()) */
                    this.setState({ChoiceOptions:ChoiceOption.join("\n")})
                }
                else{
                    this.setState({ChoiceOptions:[]})
                }
                this.setState({
                    QuestionType:response.data[0].question_type,
                    Seq:response.data[0].seq,
                    QuestionDesc:response.data[0].question_description,
                    GuidanceHint:response.data[0].guidance_hint,
                    IsMandatory: IsMandatory
                });
            })
            .catch((e) => {
                console.error(e);
            });
        } catch (error) {
            console.log(error);
        }
    }

    handleChange = e => {
        this.setState({QuestionType:e.target.value});
        if(e.target.value==="Dropdown" || e.target.value==="Multiple Choice" || e.target.value==="Check Boxes" || e.target.value === "Attachment-Multiple" ){
            this.setState({Disable:false});
        }
        else{
            this.setState({Disable:true});
        }
    }

    componentDidMount(){
        debugger;
        console.log(this.props.handleClose());
        if(this.props.SurveyTempID !== ""){
            SurveyTempID = this.props.SurveyTempID;
        }
        if(this.props.SurveyTempDtlsID !== ""){
            SurveyTempDtlsID = this.props.SurveyTempDtlsID;
            this.GetQuestionDetails();
        }
    }

    render(){
        let QueTypeCombo = this.QueTypedata();
        return(
            <div>
                <Formik initialValues={{QuestionType:this.state.QuestionType,Seq:this.state.Seq,QuestionDesc:this.state.QuestionDesc,GuidanceHint:this.state.GuidanceHint,ChoiceOptions:this.state.ChoiceOptions,IsMandatory:this.state.IsMandatory}} 
                validationSchema={validationSchema} onSubmit={this.onSubmit} enableReinitialize>
                    {formik => {
                        return <Form>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <BiztranControl control='select' type='text' label='Question Type' name='QuestionType' options={QueTypeCombo} onChange={this.handleChange} style={{ height:'30px', width:'270px'}} />
                                </Grid>
                                <Grid item xs={6}>
                                    <BiztranControl control='input' type='text' label='Seq' name='Seq' onChange={event => this.setState({Seq:event.target.value})} style={{ height:'30px', width:'100%'}} />
                                </Grid>
                                <Grid item xs={12}>
                                    <BiztranControl control='input' type='text' label='Question Desc' onChange={event => this.setState({QuestionDesc:event.target.value})} name='QuestionDesc' style={{ height:'30px', width:'100%'}} />
                                </Grid>
                                <Grid item xs={12}>
                                    <BiztranControl control='input' type='text' label='Guidence Hint' name='GuidanceHint' onChange={event => this.setState({GuidanceHint:event.target.value})} style={{ height:'30px', width:'100%'}} />
                                </Grid>
                                <Grid item xs={12}>
                                    <BiztranControl control='textarea' type='text' label='Choice Options' disabled={this.state.Disable} name='ChoiceOptions' style={{ height:'70px', width:'100%'}} />
                                </Grid>
                                <Grid item xs={3}>
                                    <BiztranControl control='input' type='checkbox' label='Is Mandatory' name='IsMandatory' style={{height:'34px'}} />
                                </Grid>
                            </Grid>
                            <BiztranContorls_01.Button type="submit" disabled={btnDisable} style={{color:'white', backgroundColor:btnColor, marginTop:'10px'}} startIcon={<SaveIcon />} 
                                text="Save"
                            />
                            <BiztranContorls_01.Button disabled={this.props.btnDeleteDisable} onClick={() => {this.DeleteTempQtnConfirm();}} style={{color:'white', backgroundColor:this.props.btnDeleteColor, marginTop:'10px'}} startIcon={<DeleteIcon />} 
                                text="Delete"
                            />
                        </Form>
                    }}
                </Formik>
            </div>
        )
    }
}

export default Add_Question;
