import React from 'react';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import global from './../Global';
import BiztranContorls_01 from '../../BiztranControls/BiztranControls';
import BiztranControl from '../../BiztranControls_01/BiztranControls';
import axios from 'axios';
import ImageUpload from "../../BiztranControls/ImageUpload";
//import UploadImageControl from "../../BiztranControls/UploadImageControl";

//let UserID = "";

const CurDate = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate();

const validationSchema = Yup.object({
    UserCode:Yup.string().required('Required!'),
    UserName:Yup.string().required('Required!'),
    password:Yup.string().required('Required!'),
    usertype:Yup.string().required('Required!'),
    isActive:Yup.string().required('Required!')
})

const onSubmit = values => { 
    try {
        debugger;
        var CreatedByID = localStorage.getItem("UserID");
        var bodyFormData = new FormData();
        //UserID = this.props.UserID
        bodyFormData.append('UserID',values.UserID === "" ? null : values.UserID);
        bodyFormData.append('Usercode',values.UserCode);
        bodyFormData.append('Username',values.UserName);
        bodyFormData.append('Password',values.password);
        bodyFormData.append('UserTypeID',values.usertype);
        bodyFormData.append('CreatedByID',CreatedByID);
        bodyFormData.append('IsActive',values.isActive);
        axios({
            url: global.BASE_URL+"User/SaveUser",
            method: 'post',
            headers: {'Content-Type': 'multipart/form-data'},
            data: bodyFormData
        })
        .then(function (response) {
            if(response.data.Response === true){
                alert("Saved Successfully.");
                this.setState({ isPopOpen: false });
            }
            else{
                alert("Save Failed.");
            }
        })
        .catch(function (response) {
            console.log(response);
        });
    } catch (error) {
        console.log(error);
    }
} 

const IsActiveOptions = [
    { key: 'Yes', value:'Y' },
    { key: 'No', value:'N' }
]

export default class User_Create extends React.Component {

    constructor(props) {
        super(props);
        this.FillUserType = this.FillUserType.bind(this);
        this.GetUserData = this.GetUserData.bind(this);
        this.state ={
            userType:[], UserCode:'', UserName:'', password:'', usertype:'', isActive:'', CreatedBy:'', CreatedOn:'', UserID:'', btnLabel:'Save',
            MainState:'initial',SelectedImage:null
        };
    }

   FillUserType(){
        axios.post(global.BASE_URL+"User/UserComboData").then((response) => {
            this.setState({ userType: response.data.UserType });
        });
    }

    UserTypedata() {
        let UserTypeCombo = this.state.userType.map((data) =>
            <option
                key={data.user_type}
                value={data.user_type_id}
            >
                {data.user_type}
            </option>
        );
        return UserTypeCombo;
    }

    handleUploadClick = event => {
        var file = event.target.files[0];
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);
    
        reader.onloadend = function(e) {
          this.setState({
            selectedFile: [reader.result]
          });
        }.bind(this);
    
        this.setState({
          mainState: "uploaded",
          selectedFile: event.target.files[0],
          imageUploaded: 1
        });
      };

    GetUserData(){
        //UserID = this.props.UserID;
        this.setState({UserID: this.props.UserID})
        var bodyFormData = new FormData();
        bodyFormData.append('UserID',this.props.UserID);
        axios({
            url: global.BASE_URL+"User/GetUserByID",
            method: 'post',
            headers: {'Content-Type': 'multipart/form-data' },
            data: bodyFormData
        })
        .then((response) => {
            this.setState({UserCode: response.data[0].user_code,
                UserName: response.data[0].user_name, 
                password: response.data[0].password, 
                usertype: response.data[0].user_type_id, 
                isActive: response.data[0].is_active, 
                CreatedBy: response.data[0].created_by, 
                CreatedOn: response.data[0].created_on
            });
        })
        .catch((e) => {
            console.error(e);
        });
    }
    
    componentDidMount(){
        debugger;
        this.FillUserType();
        if(this.props.UserID !== "" || this.props.UserID !== null){
            this.GetUserData();
            //this.setState({btnLabel: "Update"})
        }
        if(this.props.UserID === ""){
            this.setState({btnLabel: "Save"})
        }else
        {
            this.setState({btnLabel: "Update"}) 
        }
    } 

    render(){
       let UserTypeCombo = this.UserTypedata();
       
    return(
        <div>
            <Formik initialValues = {{ UserCode : this.state.UserCode ,
                                    UserName:this.state.UserName,
                                    password:this.state.password,
                                    usertype:this.state.usertype,
                                    isActive:this.state.isActive,
                                    CreatedBy:this.state.CreatedBy,
                                    UserID:this.state.UserID,
                                    btnLabel:this.state.btnLabel,
                                    CreatedOn:this.state.CreatedOn}} validationSchema = {validationSchema} onSubmit = {onSubmit} enableReinitialize>
            {formik => {    
                return <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        {/* <input type="text" defaultValue={this.state.UserCode} /> */}
                                        <BiztranControl control='input' type='text' label='User Code' name='UserCode' style={{ height:'30px', width:'300px'}} /> 
                                        <BiztranControl control='input' type='text' label='User Name' name='UserName' style={{ height:'30px', width:'300px'}} />
                                        <BiztranControl control='select' label='User Type' name='usertype' options={UserTypeCombo} style={{ height:'30px', width:'300px'}} />
                                        <BiztranControl control='input' type='password' label='Password' name='password' style={{ height:'30px', width:'300px'}} />
                                        <BiztranControl control='radio' label='IsActive' name='isActive' options={IsActiveOptions} />
                                        <BiztranControl control='input' type='text' label='Created By' name='CreatedBy' disabled={true} style={{backgroundColor:'#DCDCDC', height:'30px', width:'300px'}} />
                                        <BiztranControl control='input' type='text' label='Created On' name='CreatedOn' defaultValue={CurDate} disabled={true} style={{backgroundColor:'#DCDCDC', height:'30px', width:'300px'}} /> 
                                        <BiztranContorls_01.Button type="submit" style={{color:'white', backgroundColor:global.COLOR.Secondary, marginTop:'10px'}} startIcon={<SaveIcon />} 
                                            text={this.state.btnLabel}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                {/* <UploadImageControl MainState={this.state.MainState} SelectedImage={this.state.SelectedImage} /> */}
                                <ImageUpload UserID={this.props.UserID} FileType="Profile" />
                                {/* <BiztranContorls_01.Input type="file" /> */}
                                {/* <ButtonBase >
                                    <PersonIcon style={{height:'200px',width:'200px'}}/>
                                </ButtonBase> */}
                            </Grid>
                        </Grid>
                    </Form>
                }
            }
            </Formik> 
        </div>
    );
}
}