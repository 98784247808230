import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import { withStyles } from "@material-ui/styles";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import global from "../Global";
import BiztranContorls_01 from "../../BiztranControls/BiztranControls";
import BiztranControl from "../../BiztranControls_01/BiztranControls";
import AddMultiUsers from "./Add_Multi_Users";
import CreateNetworkFTTH from "./Create_Network_FTTH";
import axios from "axios";
import { withRouter } from "react-router-dom";

/* const initialValues = {
   ProjectName:'',ProjectIBDID:'',ProjectDes:'', updatedon:'', updatedby:'', isActive:''
} */

const theme = createMuiTheme({
  typography: {
    fontSize: 13,
  },
  textField: {
    margin: 7,
    width: "27ch",
  },
  input: {
    height: 16,
    fontSize: 7,
  },
});

const styles = (theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: global.COLOR.Secondary,
      color: "white",
      fontSize: 16,
    },
  },
});

let ProjectID = "";

const validationSchema = Yup.object({
  ProjectName: Yup.string().required("Required!"),
  ProjectDes: Yup.string().required("Required!"),
  isActive: Yup.string().required("Required!"),
});

const onSubmit = (values) => {
  try {
    var bodyFormData = new FormData();
    //var UserID = global.UserID;
    var UserID = localStorage.getItem("UserID");
    bodyFormData.append("ProjectID", ProjectID);
    bodyFormData.append("ProjectName", values.ProjectName);
    bodyFormData.append("ProjectDesc", values.ProjectDes);
    bodyFormData.append("IsActive", values.isActive);
    bodyFormData.append("UserID", UserID);
    axios({
      url: global.BASE_URL + "STLCNPTProject/SaveFTTH",
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: bodyFormData,
    })
      .then((response) => {
        alert("Saved Successfully.");
        this.state.ProjectIBDID = response.data.Message;
        this.state.linkDisable = "auto";
        this.state.linkCreateDisable = "auto";
      })
      .catch((response) => {
        console.log(response);
      });
  } catch (error) {
    console.log(error);
  }
};

const columns = [
  {
    field: "user_type",
    headerName: "User Type",
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    width: 615,
  },
  {
    field: "user_name",
    headerName: "User Name",
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    width: 615,
  },
];

const IsActiveOptions = [
  { key: "Yes", value: "Active" },
  { key: "No", value: "InActive" },
];

class Create_FTTH_Project extends React.Component {
  constructor(props) {
    super(props);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleOpenNetworkModal = this.handleOpenNetworkModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleCloseModalCreateProject =
      this.handleCloseModalCreateProject.bind(this);
    this.GetProductData = this.GetProductData.bind(this);
    var options = { year: "numeric", month: "long", day: "numeric" };
    var date = new Date(new Date()).toLocaleDateString([], options);
    this.state = {
      Project_Data: [],
      ProjectName: "",
      linkDisable: "",
      linkCreateDisable: "",
      ProjectDes: "",
      FFTxNetwork: "",
      openPopupFTTx: null,
      openModal: null,
      UserData: [],
      UsersID: [],
      openNetwokModal: null,
      Networkcolumns: [],
      Project_Name: "",
      Project_Des: "",
      created_by: "",
      created_on: "",
      is_active: null,
      rows: [],
      CurDate: date,
      UpdatedBy: localStorage.getItem("UserName"),
      networkrows: [],
      NetworkDisplay: "none",
      UserDisplay: "none",
      NetworkProjectID: "",
    };
  }

  openModal = (row) => {
    this.setState({ openNetwokModal: true, NetworkProjectID: row });
  };

  handleOpenModal = () => {
    this.setState({ openModal: true });
    //this.GetUserData();
  };

  handleOpenNetworkModal = () => {
    this.setState({ openNetwokModal: true, NetworkProjectID: "" });
    //this.GetUserData();
  };

  handleCloseModal = () => {
    this.setState({ openModal: false, openNetwokModal: false });
    this.PopulateUserData();
  };

  handleCloseModalCreateProject = () => {
    this.setState({ openPopupFTTx: false });
  };

  GetProductData() {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("ProjectID", this.state.ProjectIBDID);
      axios({
        url: global.BASE_URL + "STLCNPTProject/GetFTTHByID",
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: bodyFormData,
      })
        .then((response) => {
          this.setState({
            Project_Name: response.data.FTTHProjectData[0].ftth_project_name,
            Project_Des: response.data.FTTHProjectData[0].ftth_project_desc,
            created_by: response.data.FTTHProjectData[0].created_by,
            created_on: response.data.FTTHProjectData[0].created_on,
            is_active: response.data.FTTHProjectData[0].is_active,
          });
        })
        .catch((response) => {
          console.log(response);
        });
    } catch (error) {
      console.log(error);
    }
  }

  PopulateUserData() {
    try {
      var bodyFormData = new FormData();
      var FTTHProjectID = this.state.ProjectIBDID;
      bodyFormData.append("ProjectID", FTTHProjectID);
      axios({
        url: global.BASE_URL + "STLCNPTProject/UserList",
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: bodyFormData,
      })
        .then((response) => {
          if (response.data.length > 0) {
            this.setState({ UserDisplay: "flex" });
          } else {
            this.setState({ UserDisplay: "none" });
          }
          this.setState({ rows: response.data });
        })
        .catch((response) => {
          console.log(response);
        });
    } catch (error) {
      console.log(error);
    }
  }

  PopulateNetworkData() {
    try {
      var bodyFormData = new FormData();
      var TransportProjectID = this.state.ProjectIBDID;
      bodyFormData.append("ProjectID", TransportProjectID);
      axios({
        url: global.BASE_URL + "STLCNPTNetwork/FTTHList",
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: bodyFormData,
      })
        .then((response) => {
          if (response.data.length > 0) {
            this.setState({ NetworkDisplay: "flex" });
          } else {
            this.setState({ NetworkDisplay: "none" });
          }
          this.setState({ networkrows: response.data });
        })
        .catch((response) => {
          console.log(response);
        });
    } catch (error) {
      console.log(error);
    }
  }

  GetUserData() {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("UserName", null);
      axios({
        url: global.BASE_URL + "User/UserACList",
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: bodyFormData,
      })
        .then((response) => {
          this.setState({ UserData: response.data });
        })
        .catch((response) => {
          console.log(response);
        });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount() {
    this.state.Networkcolumns = [
      {
        field: "id",
        headerName: ".",
        renderCell: (params) => (
          <strong>
            {" "}
            <a
              href="javascript:;"
              onClick={() => {
                this.openModal(params.id);
              }}
            >
              Edit
            </a>{" "}
          </strong>
        ),
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        width: 70,
      },
      {
        field: "network_name",
        headerName: "Network Name",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        width: 250,
      },
      {
        field: "network_architecture",
        headerName: "Network Architecture",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        width: 240,
      },
      {
        field: "network_topology",
        headerName: "Network Topology",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        width: 250,
      },
      {
        field: "splitting_level",
        headerName: "Splitting Level",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        width: 200,
      },
      {
        field: "total_home_connect",
        headerName: "Total Home Connet",
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        width: 220,
      },
    ];
    this.GetUserData();
    if (this.props.location.state.FTTHProjectID != "") {
      ProjectID = this.props.location.state.FTTHProjectID;
      this.state.ProjectIBDID = this.props.location.state.FTTHProjectID;
      this.state.linkDisable = "auto";
      this.state.linkCreateDisable = "auto";
      this.GetProductData();
      this.PopulateUserData();
      this.PopulateNetworkData();
      this.GetUserData();
    } else {
      this.state.linkDisable = "none";
      this.state.linkCreateDisable = "none";
      ProjectID = "";
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Formik
          initialValues={{
            ProjectName: this.state.Project_Name,
            ProjectDes: this.state.Project_Des,
            updatedon: this.state.created_on,
            updatedby: this.state.created_by,
            isActive: this.state.is_active,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => {
            return (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <BiztranControl
                      control="input"
                      type="text"
                      label="Project Name"
                      name="ProjectName"
                      style={{ height: "30px", width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BiztranControl
                      control="textarea"
                      type="text"
                      label="Project Description"
                      name="ProjectDes"
                      style={{ height: "40px", width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <BiztranControl
                      control="input"
                      type="text"
                      label="Updated On"
                      name="updatedon"
                      disabled={true}
                      style={{
                        backgroundColor: "#DCDCDC",
                        height: "30px",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <BiztranControl
                      control="input"
                      type="text"
                      label="Updated By"
                      name="updatedby"
                      disabled={true}
                      style={{
                        backgroundColor: "#DCDCDC",
                        height: "30px",
                        width: "100%",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BiztranControl
                      control="radio"
                      label="IsActive"
                      name="isActive"
                      options={IsActiveOptions}
                    />
                  </Grid>
                </Grid>
                <BiztranContorls_01.Button
                  type="submit"
                  style={{
                    color: "white",
                    backgroundColor: global.COLOR.Secondary,
                    marginTop: "10px",
                  }}
                  startIcon={<SaveIcon />}
                  text="Save"
                />
                <Grid item xs={12}>
                  {/* <a href="javascript:;"onClick={this.handleOpenNetworkModal} >Add Network Users</a> */}
                  <div
                    style={{
                      width: "100%",
                      height: 250,
                      marginTop: 7,
                      display: this.state.NetworkDisplay,
                    }}
                    className={classes.root}
                  >
                    <ThemeProvider theme={theme}>
                      <DataGrid
                        rows={this.state.networkrows}
                        columns={this.state.Networkcolumns}
                        hideFooterPagination={true}
                        headerHeight={34}
                        rowHeight={22}
                      />
                    </ThemeProvider>
                  </div>
                </Grid>
                {/* <Grid item xs={12} style={{marginTop:3}} >
                                <a href="javascript:;" style={{pointerEvents:this.state.linkDisable}} onClick={this.handleOpenModal} >Add Users</a>
                                <div style={{ width: '100%', height: 250, marginTop:16, display:this.state.UserDisplay }}  className={classes.root} >
                                    <ThemeProvider theme={theme}>
                                        <DataGrid 
                                            rows={this.state.rows} columns={columns} hideFooterPagination={true} 
                                            headerHeight={34} rowHeight={22}
                                        />
                                    </ThemeProvider>
                                </div>
                            </Grid> */}
                <Dialog open={this.state.openModal} maxWidth="sm" fullWidth>
                  <DialogTitle
                    style={{
                      backgroundColor: global.COLOR.Secondary,
                      height: 50,
                      paddingRight: "0px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Typography
                        variant="h4"
                        component="div"
                        style={{ flexGrow: 1, color: "white", fontSize: 27 }}
                      >
                        Users Details
                      </Typography>
                      <Button
                        style={{ color: "white", paddingTop: 1 }}
                        onClick={this.handleCloseModal}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  </DialogTitle>
                  <DialogContent dividers>
                    <AddMultiUsers ProjectIBDID={this.state.ProjectIBDID} />
                  </DialogContent>
                </Dialog>
                <Dialog
                  open={this.state.openNetwokModal}
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle
                    style={{
                      backgroundColor: global.COLOR.Secondary,
                      height: 50,
                      paddingRight: "0px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Typography
                        variant="h4"
                        component="div"
                        style={{ flexGrow: 1, color: "white", fontSize: 27 }}
                      >
                        Network Details
                      </Typography>
                      <Button
                        style={{ color: "white", paddingTop: 1 }}
                        onClick={this.handleCloseModal}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  </DialogTitle>
                  <DialogContent dividers>
                    <CreateNetworkFTTH
                      ProjectIBDID={this.state.ProjectIBDID}
                      NetworkProjectID={this.state.NetworkProjectID}
                      ProjectName={this.state.Project_Name}
                    />
                  </DialogContent>
                </Dialog>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Create_FTTH_Project));
