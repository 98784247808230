import {Formik, Form} from 'formik';
import React, { Component } from 'react';
import axios from 'axios';
import BiztranControls from '../../BiztranControls/BiztranControls';
import { Redirect } from 'react-router-dom';
import global from '../Global';
import 'rc-datepicker/lib/style.css';
import 'moment/locale/en-gb.js';
import { DatePickerInput } from 'rc-datepicker';
import SearchIcon from '@material-ui/icons/Search';
import Moment from "moment";

var date = new Date();

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

export default class ToolUsageSummary extends React.Component {
  constructor(props){
    super(props);
    var fromDate = parseInt(new Date().getMonth()) +"/"+ new Date().getDate() + "/"+ new Date().getFullYear();
    var toDate = parseInt(new Date().getMonth()+1) +"/"+ new Date().getDate() + "/"+ new Date().getFullYear();
    var defaultfromDate = Moment(new Date().addDays(-30)).format("DD/MMM/YYYY");
    var defaulttoDate = new Date();
    this.state = {rows:[], columns:[], FromDate:defaultfromDate, ToDate:toDate, defaultfromDate:defaultfromDate, defaulttoDate:defaulttoDate};

  }



  PopulateData(){
    try {
        debugger;
        var bodyFormData = new FormData();
        var ProjectName = document.getElementById("projectname").value;
        var NetworkName = document.getElementById("networkname").value;
        var UserName = document.getElementById("username").value;
        bodyFormData.append('ProjectName', ProjectName);
        bodyFormData.append('NetworkName', NetworkName);
        bodyFormData.append('UserName', UserName);
        bodyFormData.append('FromDate',this.state.FromDate);
        bodyFormData.append('ToDate',this.state.ToDate);
        console.log(this.state.FromDate);
        axios({
            url: global.BASE_URL+"STLCNPTNetwork/SummaryReport",
            method: 'post',
            headers: {'Content-Type': 'multipart/form-data' },
            data: bodyFormData
        })
        .then((response) => {
          debugger;
            this.setState({rows:response.data});
        })
        .catch((response) => {
            console.log(response);
        });
    } catch (error) {
        console.log(error);
    }
}

onClear(){
  this.setState({FromDate:'',defaultfromDate:''});
}

onClear2(){
  this.setState({ToDate:'',defaulttoDate:''});
}

  componentDidMount(){
    this.PopulateData();
    this.state.columns = [
        { field: 'id', headerName: '.', renderCell: (params) => (<strong> <a href="javascript:;" onClick={() => { this.openModal(params.id); }} ></a> </strong> ), headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 10 },
        { field: 'user_name', headerName: 'User Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
        { field: 'last_modified_by', headerName: 'Last Modified By', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
        { field: 'project_name', headerName: 'Project Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
        { field: 'network', headerName: 'Network', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
        { field: 'created_on', headerName: 'Created On', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 150 },
        { field: 'imported_dp_count', headerName: 'Imported Dp Count', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
        { field: 'total_unique_dp_connected', headerName: 'Total Unique Dp Connected', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 280 },
        { field: 'total_option_count', headerName: 'Total Option Count', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
        { field: 'total_ring_count', headerName: 'Total Ring Count', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
        { field: 'total_span_count', headerName: 'Total Span Count', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 200 },
        { field: 'total_network_length_in_kms', headerName: 'Total Network Length (km)', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 250 },
        
    ];
  }
  render() {
    
    return(
      <div>
        <Formik>
          {formik => {
            return <Form>
              
              <BiztranControls.Input style={{marginTop:16, marginBottom:35}} label="Project Name" name="ProjectName" id="projectname" />
              <BiztranControls.Input style={{marginTop:16, marginBottom:35}} label="Network Name" name="NetworkName" id="networkname" />
              <BiztranControls.Input style={{marginTop:16, marginBottom:35}} label="User Name" name="UserName" id="username" />
              
              <div>
                <table>
                <tr>
                  <td>
                    <div style={{width:"223px",marginLeft:6,marginBottom:15}}>
                    <label style={{color:"grey",fontSize:12}}>From Date</label>
                    <DatePickerInput onChange={(jsDate, dateString) => this.setState({FromDate:dateString}) } locale="en" displayFormat="DD/MMM/YYYY"
                        className='my-react-component' onClear={this.onClear} showOnInputClick maxDate={new Date()} returnFormat="DD/MMM/YYYY"  defaultValue={this.state.defaultfromDate } 
                    />
                    </div>
                  </td>
                  <td>
                    <div style={{width:"223px",marginLeft:12,marginBottom:15}}>
                    <label style={{color:"grey",fontSize:12}}>To Date</label>
                    <DatePickerInput onChange={(jsDate, dateString) => this.setState({ToDate:dateString}) } locale="en" displayFormat="DD/MMM/YYYY"
                        className='my-react-component' onClear={this.onClear2} showOnInputClick maxDate={new Date()} returnFormat="DD/MMM/YYYY" defaultValue={this.state.defaulttoDate}             
                    />
                    </div>
                  </td>
                  <td>
                    <div style={{marginTop:15}}>
                    <BiztranControls.Button text="POPULATE" backcolor={global.COLOR.Secondary} startIcon={<SearchIcon/>}  onClick={() => { this.PopulateData(); }} />
                    </div>
                    </td>
                </tr>
                </table>

              <BiztranControls.DataGrid rows={this.state.rows} columns={this.state.columns} rowHeight={25} checkboxSelection={false} />
              
              </div>
              </Form>
          }

          }
        </Formik>
      </div>
    );
  }
}

