import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { withStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import global from '../Global';
import BiztranContorls_01 from '../../BiztranControls/BiztranControls';
import BiztranControl from '../../BiztranControls_01/BiztranControls';
import axios from 'axios';
import AddNetworkMultiUsers from "./Add_Network_Multi_Users";

let ProjectID = "", NetworkID = "", TransportProjectID = "" ;

const theme = createMuiTheme({
    typography: {
      fontSize: 13,
    },
});
  
const styles = theme => ({
    root: {
        '& .super-app-theme--header': {
          backgroundColor: global.COLOR.Secondary,
          color: 'white',
          fontSize: 16,
        },
    },
});



const CoreDeployTypeOptions = [
    { key: 'Mesh', value:'Mesh' },
    { key: 'Ring', value:'Ring' }
]

const PlanTypeOptions = [
    { key: 'FullAuto', value:'FullAuto' },
    { key: 'SemiAuto', value:'SemiAuto' }
]

const AggDeployTypeOptions = [
    { key: 'Dual Home', value:'Dual Home' },
    { key: 'Mesh', value:'Mesh' },
    { key: 'Ring', value:'Ring' }
]

const AccessDeployTypeOptions = [
    { key: 'Dual Home', value:'Dual Home' },
    { key: 'Mesh', value:'Mesh' },
    { key: 'Ring', value:'Ring' },
    { key: 'RF', value:'RF' },
    { key: 'Star', value:'Star' }
]

const validationSchema = Yup.object({
    ProjectName:Yup.string().required('Required!'),
    NetworkName:Yup.string().required('Required!'),
    Zone:Yup.string().required('Required!'),
    CoreDeployType:Yup.string().required('Required!'),
    AggDeployType:Yup.string().required('Required!'),
    AccessDeployType:Yup.string().required('Required!')
})

const onSubmit = values => {
    try {
        debugger;
        var UserID = localStorage.getItem("UserID");
        var bodyFormData = new FormData();
        bodyFormData.append('NetworkID',NetworkID);
        bodyFormData.append('NetworkName',values.NetworkName);
        bodyFormData.append('ZoneID',values.Zone);
        bodyFormData.append('CoreDeployType',values.CoreDeployType);
        bodyFormData.append('AggDeployType',values.AggDeployType);
        bodyFormData.append('AccessDeployType',values.AccessDeployType);
        bodyFormData.append('CoreDpCount',values.MaxCoreDpCount === null ? '0' : values.MaxCoreDpCount);
        bodyFormData.append('AggDpCount',values.MaxAggDpCount === null ? '0' : values.MaxAggDpCount);
        bodyFormData.append('AccessDpCount',values.MaxAcessDpCount === null ? '0' : values.MaxAcessDpCount);
        bodyFormData.append('AvgAttemptCount',values.AverageAttempt === null ? '0' : values.AverageAttempt);
        bodyFormData.append('ApproxDirectAPIHitCount',values.ApiHitCount === null ? '0' : values.ApiHitCount);
        bodyFormData.append('TotalDirectAPICost',values.TotalApiCost === null ? '0' : values.TotalApiCost);
        bodyFormData.append('ApproxAPICostINR',values.ApiCostINR === null ? '0' : values.ApiCostINR);
        bodyFormData.append('UserID',UserID);
        axios({
            url: global.BASE_URL+"STLCNPTNetwork/SaveTransport",
            method: 'post',
            headers: {'Content-Type': 'multipart/form-data' },
            data: bodyFormData
        })
        .then((response) => {
            try{
                if(response.data.Response === true){
                    alert("Saved Successfully")
                }
                else{
                    alert("Failed")
                }
                }
            catch(error){
                alert("Failed")
            }
            
        })
        .catch((response) => {
            console.log(response);
        });
    } 
    catch (error) {
        console.log(error);
    }
} 

const columns = [
    { field: 'user_type', headerName: 'User Type', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 450 },
    { field: 'user_name', headerName: 'User Name', headerClassName: 'super-app-theme--header', headerAlign: 'center', width: 440 },
];

class Create_Network extends React.Component {

    constructor(props) {
        super(props);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.calculateHitCount = this.calculateHitCount.bind(this);
        this.DeleteNetwork = this.DeleteNetwork.bind(this);
        this.state = {
            openModal:null, rows:[], UserDisplay:'none',ProjectName:'',NetworkName:'',Zone:'',CoreDeployType:'',AggDeployType:'',AccessDeployType:'',ZoneData:[],
            btnDisable:null,btnColor:null
        };
    }

    handleOpenModal = () => {
        this.setState({ openModal: true });
    };

    handleCloseModal = () => {
        this.setState({ openModal: false });
        this.PopulateUserData();
    };


    calculateHitCount = (e) => {
    debugger;
    this.setState({
        ApiHitCount:'',
        ApiCostUSD0:'',
        ApiCostUSD1:'',
        ApiCostUSD2:'',
        ApiCostUSD3:'',
        TotalApiCost:'',
        ApiCostUSD:'',
        ApiCostUSDI:'',
        ApiCostINR:'',
    });
    console.log(this.state.NetworkName);
    console.log(this.state.MaxCoreDpCount);
    var PlanType = '';
    var AverageAttempt = 0;
    var MaxAcessDpCount = 0;
    var MaxAggDpCount = 0;
    var MaxCoreDpCount = 0;
    if(this.state.AverageAttempt !== "" && this.state.AverageAttempt !== undefined) {
        AverageAttempt = this.state.AverageAttempt;
    }
    if(this.state.MaxAcessDpCount !== "" && this.state.MaxAcessDpCount !== undefined){
        MaxAcessDpCount = this.state.MaxAcessDpCount;
    }
    if(this.state.MaxAggDpCount !== "" && this.state.MaxAcessDpCount !== undefined){
        MaxAggDpCount = this.state.MaxAggDpCount;
    }
    if(this.state.MaxCoreDpCount !== "" && this.state.MaxCoreDpCount !== undefined){
        MaxCoreDpCount = this.state.MaxCoreDpCount;
    }
    if(e !== '' && e !== undefined){
    let targetvalue = e.target.value;
    let targetname = e.target.name;
    if (targetvalue !== "") {
      if (targetname === "PlanType") {
        this.setState({PlanType:targetvalue});
        PlanType = targetvalue;
      }
      if (targetname === "AverageAttempt") {
        this.setState({AverageAttempt:targetvalue});
        AverageAttempt = targetvalue;
      }
      if(targetname === "MaxAcessDpCount"){
          this.setState({MaxAcessDpCount:targetvalue});
          MaxAcessDpCount = targetvalue;
      }
      if(targetname === "MaxAggDpCount"){
          this.setState({MaxAggDpCount:targetvalue});
          MaxAggDpCount = targetvalue;
      }
      if(targetname === "MaxCoreDpCount"){
          this.setState({MaxCoreDpCount:targetvalue});
          MaxCoreDpCount = targetvalue;
      }
    }
    else{
        if (targetname === "PlanType") {
            this.setState({PlanType:''});
            PlanType = '';
          }
          if (targetname === "AverageAttempt") {
            this.setState({AverageAttempt:''});
            AverageAttempt = '0';
            this.setState({
                ApiHitCount:'',
                ApiCostUSD0:'',
                ApiCostUSD1:'',
                ApiCostUSD2:'',
                ApiCostUSD3:'',
                TotalApiCost:'',
                ApiCostUSD:'',
                ApiCostUSDI:'',
                ApiCostINR:'',
            });
          }
          if(targetname === "MaxAcessDpCount"){
              this.setState({MaxAcessDpCount:''});
              MaxAcessDpCount = '0';
          }
          if(targetname === "MaxAggDpCount"){
              this.setState({MaxAggDpCount:''});
              MaxAggDpCount = '0';
          }
          if(targetname === "MaxCoreDpCount"){
            this.setState({MaxCoreDpCount:''});
            MaxCoreDpCount = '0';
        }
    }
}else{
    PlanType = this.state.PlanType;
    AverageAttempt = this.state.AverageAttempt;
    MaxAcessDpCount = this.state.MaxAcessDpCount;
    MaxAggDpCount = this.state.MaxAggDpCount;
    MaxCoreDpCount = this.state.MaxCoreDpCount;
}
    var DistMatrixApiCost1 = 0;
    var DistMatrixApiCost2 = 0;
    var DistMatrixApiCost3 = 0;
    var DistMatrixApiCost4 = 0;
    var DistMatrixApiCost5 = 0;
    var DistMatrixApiCost6 = 0;
    var TotalNodeCount = 0;
    var DirApiCostUSD0 = 0;
    var DirApiCostUSD1 = 0;
    var DirApiCostUSD2 = 0;
    var DirApiCostUSD3 = 0;
    var AverageAcessNodes = 0;
    var ApxDistMatrixApiHit = 0;
    var TotalDistMatrixApiCost = 0;
    var ApiCostInUSD = 0;
    var TotalCount = 0;
    var ApxDirApiHitCount = 0;
    var TotalDirApiCost = 0;
    var DirApiCostUSD = 0;
    var ApxApiCostUSDI = 0;
    var ApxApiCostINR = 0;
    var MaxMonApiCostInUSD = 0;
    var MaxMonApiCostInINR = 0; 
    var CoreNodeCount = MaxCoreDpCount;
    var AggNodeCount = MaxAggDpCount;
    var AccessNodeCount = MaxAcessDpCount;
    console.log(AverageAttempt);
    debugger;
    TotalNodeCount = parseInt(CoreNodeCount) + parseInt(AccessNodeCount) + parseInt(AggNodeCount);
    //if(PlanType == "SemiAuto"){
    debugger;
    ApxDirApiHitCount=parseInt(AverageAttempt)*TotalNodeCount;
    this.setState({ ApiHitCount: ApxDirApiHitCount});
    if(ApxDirApiHitCount < 100001){
        var DirApiCostUSD0 = Math.round((ApxDirApiHitCount*0.005),0);
        this.setState({ ApiCostUSD0: DirApiCostUSD0});
    }else{
        var DirApiCostUSD0 = Math.round((100000*0.005),0);
        this.setState({ ApiCostUSD0: DirApiCostUSD0});
    }
    if(ApxDirApiHitCount > 100000 && ApxDirApiHitCount <= 500000){
        var DirApiCostUSD1 = Math.round((ApxDirApiHitCount-100000)*0.004,0);
        this.setState({ ApiCostUSD1: DirApiCostUSD1});
        console.log("DirApiCostUSD1",DirApiCostUSD1);
    }else if(ApxDirApiHitCount > 500000){
        var DirApiCostUSD1 = Math.round((400000*0.004),0);
        this.setState({ ApiCostUSD1: DirApiCostUSD1});
        console.log("DirApiCostUSD1",DirApiCostUSD1);
    }
    if(ApxDirApiHitCount > 500000 && ApxDirApiHitCount <= 1000000){
        var DirApiCostUSD2 = Math.round((ApxDirApiHitCount-500000)*0.003,0);
        this.setState({ ApiCostUSD2: DirApiCostUSD2});
    }else if(ApxDirApiHitCount >1000000){
        var DirApiCostUSD2 = Math.round((1000000*0.003),0);
        this.setState({ ApiCostUSD2: DirApiCostUSD2});
    }
    if(ApxDirApiHitCount > 1000000 && ApxDirApiHitCount <= 5000000){
        var DirApiCostUSD3 = Math.round(((ApxDirApiHitCount-1000000)*0.0015),0);
        this.setState({ ApiCostUSD3: DirApiCostUSD3});
    }else if(ApxDirApiHitCount > 5000000){
        var DirApiCostUSD3 = Math.round(5000000*0.0015,0);
        this.setState({ ApiCostUSD3: DirApiCostUSD3});
    }
    TotalDirApiCost = parseInt(DirApiCostUSD0)+parseInt(DirApiCostUSD1)+parseInt(DirApiCostUSD2)+parseInt(DirApiCostUSD3);
    DirApiCostUSD = Math.round(TotalDirApiCost+(0.1*(TotalDirApiCost)),0);
    ApxApiCostUSDI = DirApiCostUSD;
    ApxApiCostINR = DirApiCostUSD*76;
    this.setState({ TotalApiCost: TotalDirApiCost});
    this.setState({ ApiCostUSD: DirApiCostUSD});
    this.setState({ ApiCostUSDI: ApxApiCostUSDI});
    this.setState({ ApiCostINR: ApxApiCostINR});
        // //}
    // //else{



    //     console.log("TotalNodeCount",TotalNodeCount)
    //     AverageAcessNodes = Math.round(AccessNodeCount/AggNodeCount,0);
    //     console.log("AverageAcessNodes",AverageAcessNodes);
    //     ApxDistMatrixApiHit = (CoreNodeCount*CoreNodeCount)+((AggNodeCount*AggNodeCount))+((AverageAcessNodes*AverageAcessNodes)*AggNodeCount);
    //     console.log("ApxDistMatrixApiHit",ApxDistMatrixApiHit);    
    //     if(ApxDistMatrixApiHit < 100001){
    //         DistMatrixApiCost1 = Math.round((ApxDistMatrixApiHit*0.005),0);
    //     }else{
    //         DistMatrixApiCost1 = Math.round((100000*0.005),0);
    //         console.log("DistMatrixApiCost1",DistMatrixApiCost1);
    //     }
    //     if(ApxDistMatrixApiHit>100000 && ApxDistMatrixApiHit<=500000){
    //         DistMatrixApiCost2 = Math.round(((ApxDistMatrixApiHit-100000)*0.004),0);
    //         console.log("DistMatrixApiCost2",DistMatrixApiCost2);
    //     }else if(ApxDistMatrixApiHit > 500000){
    //         DistMatrixApiCost2 = Math.round((400000*0.004),0);
    //         console.log("DistMatrixApiCost2",DistMatrixApiCost2);
    //     }
    //     if(ApxDistMatrixApiHit > 500000 && ApxDistMatrixApiHit <= 1000000){
    //         DistMatrixApiCost3 = Math.round(((ApxDistMatrixApiHit-500000)*0.003),0);
    //         console.log("DistMatrixApiCost3",DistMatrixApiCost3);
    //     }else if(ApxDistMatrixApiHit > 1000000){
    //         DistMatrixApiCost3 = Math.round((1000000*0.003),0);
    //         console.log("DistMatrixApiCost3",DistMatrixApiCost3);
    //     }
    //     if(ApxDistMatrixApiHit > 1000000 && ApxDistMatrixApiHit <= 5000000){
    //         DistMatrixApiCost4 = Math.round(((ApxDistMatrixApiHit-1000000)*0.0015),0);
    //         console.log("DistMatrixApiCost4",DistMatrixApiCost4);
    //     }else if(ApxDistMatrixApiHit > 5000000){
    //         DistMatrixApiCost4 = Math.round((5000000*0.0015),0);
    //         console.log("DistMatrixApiCost4",DistMatrixApiCost4);
    //     }
    //     if(ApxDistMatrixApiHit > 5000000 && ApxDistMatrixApiHit <= 10000000){
    //         DistMatrixApiCost5 = Math.round(((ApxDistMatrixApiHit-10000000)*0.00038),0);
    //         console.log("DistMatrixApiCost5",DistMatrixApiCost5);
    //     }else if(ApxDistMatrixApiHit > 10000000){
    //         DistMatrixApiCost5 = Math.round((10000000*0.00038),0);
    //         console.log("DistMatrixApiCost5",DistMatrixApiCost5);
    //     }
    //     if(ApxDistMatrixApiHit > 10000000){
    //         DistMatrixApiCost6 = Math.round(((ApxDistMatrixApiHit-10000000)*0.00011),0);
    //         console.log("DistMatrixApiCost6",DistMatrixApiCost6);
    //     }else {
    //         DistMatrixApiCost6 = Math.round((10000000*0.00011),0);
    //         console.log("DistMatrixApiCost6",DistMatrixApiCost6);
    //     }
    //     TotalDistMatrixApiCost = parseInt(DistMatrixApiCost1)+parseInt(DistMatrixApiCost2)+parseInt(DistMatrixApiCost3)+parseInt(DistMatrixApiCost4)+parseInt(DistMatrixApiCost5)+parseInt(DistMatrixApiCost6);
    //     console.log("TotalDistMatrixApiCost",TotalDistMatrixApiCost)
    //     ApiCostInUSD = Math.round((parseInt(TotalDistMatrixApiCost)+parseInt(0.1*TotalDistMatrixApiCost)),0);
    //     console.log("ApiCostInUSD",ApiCostInUSD)
    //     TotalCount = parseInt(CoreNodeCount) + parseInt(AccessNodeCount) + parseInt(AggNodeCount);
    //     ApxDirApiHitCount = TotalCount*8;
    //     this.setState({ ApiHitCount: ApxDirApiHitCount});
    //     if(ApxDirApiHitCount<100001){
    //         DirApiCostUSD0 = Math.round((ApxDirApiHitCount*0.005),0)
    //         this.setState({ ApiCostUSD0: DirApiCostUSD0});
    //     }else{
    //         DirApiCostUSD0 = Math.round((100000*0.005),0);
    //         this.setState({ ApiCostUSD0: DirApiCostUSD0});
    //     }
    //     if(ApxDirApiHitCount>100000 && ApxDirApiHitCount<=500000){
    //         DirApiCostUSD1 = Math.round(((ApxDirApiHitCount-100000)*0.004),0);
    //         this.setState({ ApiCostUSD1: DirApiCostUSD1});
    //     }else if(ApxDirApiHitCount>500000){
    //         DirApiCostUSD1 = Math.round((400000*0.004),0);
    //         this.setState({ ApiCostUSD1: DirApiCostUSD1});
    //     }
    //     if(ApxDirApiHitCount > 500000 && ApxDirApiHitCount <=1000000){
    //         DirApiCostUSD2 = Math.round(((ApxDirApiHitCount-500000)*0.003),0);
    //         this.setState({ ApiCostUSD2: DirApiCostUSD2});
    //     }else if(ApxDirApiHitCount>1000000){
    //         DirApiCostUSD2 = Math.round((1000000*0.003),0);
    //         this.setState({ ApiCostUSD2: DirApiCostUSD2});
    //     }
    //     if(ApxDirApiHitCount > 1000000 && ApxDirApiHitCount <= 5000000){
    //         DirApiCostUSD3 = Math.round(((ApxDirApiHitCount-1000000)*0.0015),0);
    //         this.setState({ ApiCostUSD3: DirApiCostUSD3});
    //     }else if(ApxDirApiHitCount> 5000000){
    //         DirApiCostUSD3 = Math.round((5000000*0.0015),0);
    //         this.setState({ ApiCostUSD3: DirApiCostUSD3});
    //     }
    //     TotalDirApiCost = parseInt(DirApiCostUSD0)+parseInt(DirApiCostUSD1)+parseInt(DirApiCostUSD2)+parseInt(DirApiCostUSD3);
    //     DirApiCostUSD = Math.round((TotalDirApiCost)+(0.1*TotalDirApiCost),0);
    //     ApxApiCostUSDI = parseInt(ApiCostInUSD)+parseInt(DirApiCostUSD);
    //     ApxApiCostINR = ApxApiCostUSDI*76;
    //     MaxMonApiCostInUSD = Math.round(ApxApiCostUSDI-200,0);
    //     MaxMonApiCostInINR = MaxMonApiCostInUSD*76;
    //     this.setState({ TotalApiCost: TotalDirApiCost});
    //     this.setState({ ApiCostUSD: DirApiCostUSD});
    //     this.setState({ ApiCostUSDI: ApxApiCostUSDI});
    //     this.setState({ ApiCostINR: ApxApiCostINR});



    //}

    };

    PopulateUserData(){
        try {
            var bodyFormData = new FormData();
            bodyFormData.append('NetworkID',NetworkID);
            
            axios({
                url: global.BASE_URL+"STLCNPTNetwork/UserList",
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
            .then((response) => {
                if(response.data.length > 0){
                    this.setState({UserDisplay:'flex'});
                }
                else{
                    this.setState({UserDisplay:'none'});
                }
                this.setState({ rows:response.data });
            })
            .catch((response) => {
                console.log(response);
            });
        } catch (error) {
            console.log(error);
        }
    }

    GetNetworkDetails(){
        try {
            var bodyFormData = new FormData();
            bodyFormData.append('NetworkID',NetworkID);
            
            axios({
                url: global.BASE_URL+"STLCNPTNetwork/GetTransportByID",
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
            .then((response) => {
                debugger;
                if(response.data.NetworkData[0].average_planning_attempts !== null){
                    this.setState({AverageAttempt:response.data.NetworkData[0].average_planning_attempts});
                }
                else{
                    this.setState({AverageAttempt:5});
                }
                if(response.data.NetworkData[0].access_dp_count !== null){
                    this.setState({MaxAcessDpCount:response.data.NetworkData[0].access_dp_count});
                }
                else{
                    this.setState({MaxAcessDpCount:0});
                }
                if(response.data.NetworkData[0].agg_dp_count !== null){
                    this.setState({MaxAggDpCount:response.data.NetworkData[0].agg_dp_count});
                }
                else{
                    this.setState({MaxAggDpCount:0});
                }
                if(response.data.NetworkData[0].core_dp_count !== null){
                    this.setState({MaxCoreDpCount:response.data.NetworkData[0].core_dp_count});
                }
                else{
                    this.setState({MaxCoreDpCount:0});
                }
                this.setState({
                    NetworkName:response.data.NetworkData[0].network_name,
                    Zone:response.data.NetworkData[0].project_zn_id,
                    CoreDeployType:response.data.NetworkData[0].core_deploy_type,
                    AggDeployType:response.data.NetworkData[0].agg_deploy_type,
                    AccessDeployType:response.data.NetworkData[0].access_deploy_type,
                    //MaxCoreDpCount:response.data.NetworkData[0].core_dp_count,
                    //MaxAggDpCount:response.data.NetworkData[0].agg_dp_count,
                    //MaxAcessDpCount:response.data.NetworkData[0].access_dp_count,
                    //AverageAttempt:response.data.NetworkData[0].average_planning_attempts,
                });
                this.calculateHitCount('');
            })
            .catch((response) => {
                console.log(response);
            });
        } catch (error) {
            console.log(error);
        }
    }

    GetZoneData(){
        try {
            var bodyFormData = new FormData();
            var ProjectID = this.props.ProjectIBDID;
            bodyFormData.append('ProjectID',ProjectID);
            axios({
                url: global.BASE_URL+"STLCNPTNetwork/ComboData",
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
            .then((response) => {
                this.setState({ZoneData:response.data.TransportZone})
            })
            .catch((response) => {
                console.log(response);
            });
        } 
        catch (error) {
            console.log(error);
        }
    }

    ZoneDataOptions() {
        let ZoneCombo = this.state.ZoneData.map((data) =>
            <option
                key={data.zone_name}
                value={data.project_zn_id}
            >
                {data.zone_name}
            </option>
        );
        return ZoneCombo;
    }

    CoreTypedata() {
        let CoreTypeCombo = CoreDeployTypeOptions.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return CoreTypeCombo;
    }

    PlanTypedata() {
        let PlanTypeCombo = PlanTypeOptions.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return PlanTypeCombo;
    }

    AggTypedata() {
        let AggTypeCombo = AggDeployTypeOptions.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return AggTypeCombo;
    }

    AccessTypedata() {
        let AccessTypeCombo = AccessDeployTypeOptions.map((data) =>
            <option
                key={data.key}
                value={data.value}
            >
                {data.key}
            </option>
        );
        return AccessTypeCombo;
    }

    DeleteNetwork(){
        try {
            var bodyFormData = new FormData();
            bodyFormData.append('NetworkID',NetworkID);
            axios({
                url: global.BASE_URL+"STLCNPTNetwork/DeleteTransport",
                method: 'post',
                headers: {'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
            .then((response) => {
                try {
                    if(response.data.Response === true){
                        alert(response.data.Message);
                        this.setState({ openModal: false });  
                    }  
                    else{
                        alert("Failed to Delete Network")
                    }     
                } catch (error) {
                    alert("Failed to Delete Network")
                }          
            })
            .catch((response) => {
                console.log(response);
            });
        } 
        catch (error) {
            console.log(error);
        }
    }

    DeleteConformation(){
        const confirmBox = window.confirm(
          "Do you really want to delete Network?"
        )
        if (confirmBox === true) {
          this.DeleteNetwork();
        }
    }

    componentDidMount(){
        this.setState({PlanType:'SemiAuto'});
        //this.setState({AverageAttempt:5});
        this.GetZoneData();
        if(this.props.NetworkProjectID !== ""){
            TransportProjectID = this.props.IBDProjectID;
            ProjectID = this.props.IBDProjectID;
            NetworkID = this.props.NetworkProjectID
            this.PopulateUserData();
            this.GetNetworkDetails();
            //this.calculateHitCount('');
            this.setState({btnDisable:false,btnColor:'red'});
        }
        else{
            ProjectID = "";
            NetworkID = "";
            this.setState({btnDisable:true,btnColor:'#dddddd'});
        }
    }

    render(){
        const { classes } = this.props; 
        let CoreTypeCombo = this.CoreTypedata();
        let AggTypeCombo = this.AggTypedata();
        let AccessTypeCombo = this.AccessTypedata();
        let ZoneCombo = this.ZoneDataOptions();
        let PlanTypeCombo = this.PlanTypedata();
    return(
            <div>
                <Formik initialValues = {{ProjectName:this.props.ProjectName,NetworkName:this.state.NetworkName,Zone:this.state.Zone,CoreDeployType:this.state.CoreDeployType,AggDeployType:this.state.AggDeployType,AccessDeployType:this.state.AccessDeployType,MaxCoreDpCount:this.state.MaxCoreDpCount,MaxAggDpCount:this.state.MaxAggDpCount,MaxAcessDpCount:this.state.MaxAcessDpCount,PlanType:this.state.PlanType,
                AverageAttempt:this.state.AverageAttempt,ApiHitCount:this.state.ApiHitCount,ApiCostUSD0:this.state.ApiCostUSD0,ApiCostUSD1:this.state.ApiCostUSD1,ApiCostUSD2:this.state.ApiCostUSD2,ApiCostUSD3:this.state.ApiCostUSD3,TotalApiCost:this.state.TotalApiCost,ApiCostUSD:this.state.ApiCostUSD,ApiCostUSDI:this.state.ApiCostUSDI,ApiCostINR:this.state.ApiCostINR}}
                validationSchema = {validationSchema} onSubmit={onSubmit} enableReinitialize>
                {formik => {
                    return <Form>
                            <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <BiztranControl control='input' type='text' label='Network Name' name='NetworkName' style={{ height:'30px', width:'100%'}} onChange={(e)=>{this.setState({NetworkName:e.target.value});}} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BiztranControl control='input' type='text' label='Project Name' name='ProjectName' style={{ height:'30px', width:'100%'}} onChange={(e)=>{this.setState({ProjectName:e.target.value});}} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BiztranControl control='select' label='Zone' name='Zone' options={ZoneCombo} style={{ height:'30px', width:'100%'}} onChange={(e)=>{this.setState({Zone:e.target.value});}} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BiztranControl control='select' label='Core Deploy Type' name='CoreDeployType' options={CoreTypeCombo} style={{ height:'30px', width:'100%'}} onChange={(e)=>{this.setState({CoreDeployType:e.target.value});}} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BiztranControl control='select' label='Agg Deploy Type' name='AggDeployType' options={AggTypeCombo} style={{ height:'30px', width:'100%'}} onChange={(e)=>{this.setState({AggDeployType:e.target.value});}} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BiztranControl control='select' label='Access Deploy Type' name='AccessDeployType' options={AccessTypeCombo} style={{ height:'30px', width:'100%'}} onChange={(e)=>{this.setState({AccessDeployType:e.target.value});}} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BiztranControl control='input' type='numeric' label='Max Core DP Count' name='MaxCoreDpCount' style={{ height:'30px', width:'100%'}} onChange={(e)=>{this.calculateHitCount(e);}} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BiztranControl control='input' type='numeric' label='Max Agg DP Count' name='MaxAggDpCount' style={{ height:'30px', width:'100%'}} onChange={(e)=>{this.calculateHitCount(e);}} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BiztranControl control='input' type='numeric' label='Max Acess DP Count' name='MaxAcessDpCount' style={{ height:'30px', width:'100%'}} onChange={(e)=>{this.calculateHitCount(e);}} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BiztranControl control='select' label='Plan Type' name='PlanType' options={PlanTypeCombo} name="PlanType" style={{ height:'30px', width:'100%'}} onChange={(e)=>{this.calculateHitCount(e);}}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <BiztranControl control='input' type='text' label='Average Attempt' name='AverageAttempt' onChange={(e)=>{this.calculateHitCount(e);}} style={{ height:'30px', width:'100%'}} />
                                    </Grid>
                                    
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                    <label style={{ fontSize:18, width:'100%', marginTop:10, backgroundColor:"lightblue"}}>Approx Directions API Hit Count </label>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiHitCount' style={{ height:'28px', width:'100%'}} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                    <label style={{ fontSize:15, marginTop:5, width:'100%' }}>Directions API Cost in USD (HC-0-100000)</label>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostUSD0' style={{ height:'28px', width:'100%', marginTop:-15}} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                    <label style={{ fontSize:15, width:'100%'}}>Directions API Cost in USD (HC-100001-500000)</label>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostUSD1' style={{ height:'28px', width:'100%'}} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                    <label style={{ fontSize:15, width:'100%'}}>Directions API Cost in USD (HC-500001-1000000)</label>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostUSD2' style={{ height:'28px', width:'100%'}} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                    <label style={{ fontSize:15, width:'100%'}}>Directions API Cost in USD (HC-1000001-5000000)</label>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostUSD3' style={{ height:'28px', width:'100%'}} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                    <label style={{ fontSize:18, width:'100%', marginTop:10, backgroundColor:"lightblue"}}>Total Directions API Cost</label>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='TotalApiCost' style={{ height:'28px', width:'100%'}} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                    <label style={{ fontSize:15, width:'100%'}}>Directions API Cost in USD (after 10% Buffer - Directions API)</label>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostUSD' style={{ height:'28px', width:'100%'}} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                    <label style={{ fontSize:15, width:'100%'}}>Approx API Cost in USD (individual Plan)</label>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostUSDI' style={{ height:'28px', width:'100%'}} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                    <label style={{ fontSize:18, width:'100%', backgroundColor:"lightblue"}}>Approx API Cost in INR @76 Rup/USD (Individual Plan)</label>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <BiztranControl control='input' disabled={true} type='text' name='ApiCostINR' style={{ height:'28px', width:'100%'}} />
                                    </Grid>
                                </Grid>
                                    <BiztranContorls_01.Button type="submit" style={{color:'white', backgroundColor:global.COLOR.Secondary, marginTop:'10px'}} startIcon={<SaveIcon />} 
                                        text="Save"
                                    />
                                    <BiztranContorls_01.Button disabled={this.state.btnDisable} style={{color:'white', backgroundColor:this.state.btnColor, marginTop:'10px'}} startIcon={<DeleteIcon />} onClick={() => {this.DeleteConformation();}}
                                        text="Delete"
                                    />
                                <Grid item xs={12}>
                                    <a href="javascript:;" onClick={this.handleOpenModal} >Add Users</a>
                                    <div style={{ width: '100%', marginTop:3, display:this.state.UserDisplay }}  className={classes.root} >
                                        <ThemeProvider theme={theme}>
                                            <DataGrid 
                                                autoHeight
                                                rows={this.state.rows} 
                                                columns={columns} 
                                                hideFooterPagination={true} 
                                                headerHeight={34} 
                                                rowHeight={22}
                                            />
                                        </ThemeProvider>
                                    </div>
                                </Grid> 
                            <Dialog open={this.state.openModal} maxWidth="sm" fullWidth >
                                <DialogTitle style={{ backgroundColor:global.COLOR.Secondary,height:50, paddingRight:'0px'}}>
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant="h4" component="div" style={{ flexGrow: 1, color:'white', fontSize:27 }}>
                                            Users Details
                                        </Typography>
                                        <Button
                                            style={{color:'white',paddingTop:1}}
                                            onClick={this.handleCloseModal}>
                                            <CloseIcon />
                                        </Button>
                                    </div>
                                </DialogTitle>
                                <DialogContent dividers>
                                    <AddNetworkMultiUsers ProjectIBDID={this.state.ProjectIBDID} NetworkID={this.props.NetworkProjectID} Group="Transport"/>
                                </DialogContent>
                            </Dialog>
                        </Form>
                    }
                }
                </Formik> 
            </div>
        );
    }
}

export default withStyles(styles)(Create_Network);
