import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { CardBody, CardGroup, InputGroup, InputGroupAddon, InputGroupText, Form, Input } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Login.css";
import STLlogo from '../components/images/LogoSTL.png';
import usericon from '../components/images/usericon.png';
import lockicon from '../components/images/lockicon.png';
import axios from 'axios';
import global from './Global';

class Login extends Component {
    constructor(props) {
        super(props);
        this.UpdateCustomer = this.UpdateCustomer.bind(this);
        const token = localStorage.getItem("token")
        let loggedIn = true
        if (token == null) {
            loggedIn = false
        }
        this.state = {
            post: {
                username: "",
                Password: "",
                showlabel: "",
                email: "",
                pass: "",
                loggedIn
            }
        };

    }

    // redirect to home if already logged in
    handleusername = (text) => {

        this.setState({ email: text })
    }

    handlePassword = (text) => {
        this.setState({ pass: text })
    }

    handleChange = e => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            post: { ...prevState.post, [name]: value }
        }));
    };

    UpdateCustomer(post) {
        debugger;
        if (post.post.username != "" && post.post.Password != "") {
            var bodyFormData = new FormData();
            bodyFormData.append('Usercode', post.post.username);
            bodyFormData.append('Password', (post.post.Password).toString());
            axios({
                url: global.BASE_URL + "User/Login",
                method: 'post',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: bodyFormData
            })
                .then((response) => {
                    if (response.data.length > 0) {
                        localStorage.setItem("UserID", response.data[0].user_id);
                        localStorage.setItem("UserName", response.data[0].user_name);
                        localStorage.setItem("UserType", response.data[0].user_type);
                        document.getElementById('lbl1').innerHTML = ''
                        this.setState(prevState => ({
                            post: { ...prevState.post, loggedIn: true }
                        }))
                    } else {
                        document.getElementById('lbl1').innerHTML = 'Invalid Login'
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        else {
            //alert('Please Enter username & Password')
            document.getElementById('lbl1').innerHTML = 'Please Enter username & Password'
        }
    }

    onFormSubmit = (event) => {
        event.preventDefault();
    };

    render() {
        if (this.state.post.loggedIn) {
            return <Redirect to="/Home" />
        }
        return (
            <div className="LoginContainer">
                <div>
                    <div className="login">
                        <div>
                            <div className="app flex-row align-items-center">
                                <Row className="justify-content-center">
                                    <Col md="8">

                                        <CardGroup>
                                            <Card className="p-4">
                                                <CardBody>
                                                    <Form onSubmit={this.onFormSubmit}>
                                                        <h4 style={{ textDecorationColor: "#0e2f44" }}>Sign In To Your Account</h4>
                                                        <InputGroup className="mb-3">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <img src={usericon} style={{ height: "20px" }} />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input type="text" name="username" placeholder="Username" autoComplete="username" onChange={this.handleChange} value={this.state.post.username} />
                                                        </InputGroup>
                                                        <InputGroup className="mb-4">
                                                            <InputGroupAddon addonType="prepend">
                                                                <InputGroupText>
                                                                    <img src={lockicon} style={{ height: "20px" }} />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                            <Input type="password" name="Password" placeholder="Password" autoComplete="current-password" onChange={this.handleChange} value={this.state.post.Password} />
                                                        </InputGroup>
                                                        <Button type="submit" block bsSize="large" style={{ backgroundColor: "#e86915" }} onClick={e => this.UpdateCustomer(this.state)}>
                                                            Login</Button>
                                                        <label id="lbl1" style={{ visibility: this.state.showlabel, fontSize: "12px", color: "red" }}></label>
                                                    </Form>
                                                </CardBody>
                                            </Card>

                                            <Card className="text-white  py-5 d-md-down-none" style={{ width: '44%', backgroundColor: "#e86915" }}>
                                                <CardBody className="text-center">
                                                    <div>
                                                        <img src={STLlogo} style={{ height: "70px" }} />
                                                        {/* <h2>STL</h2> */}
                                                        <p style={{ fontSize: "20px", padding: "18px" }}>Convergence of wireless and wireline connectivity</p>

                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </CardGroup>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
