import React from 'react';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import {Formik, Form} from 'formik';
import global from './Global';
import axios from 'axios';
import * as Yup from 'yup';
//import { Checkbox } from '@material-ui/core';
import { DatePickerInput } from 'rc-datepicker';
import BiztranControl from './../BiztranControls/BiztranControls'
import BiztranControls_01 from "./../BiztranControls_01/BiztranControls";
import SaveIcon from '@material-ui/icons/Save';

let ErrorID = ""; 
let IsFixed = null;

const theme = createMuiTheme({
    typography: {
      fontSize: 13,
    },
});
const validationSchema = Yup.object({
    FixedBy:Yup.string().required('Required!'),
    FixRemarks:Yup.string().required('Required!'),

})
const onSubmit = values => { 
    try {
        var bodyFormData = new FormData();
        bodyFormData.append('ErrorID',ErrorID);
        bodyFormData.append('IsFixed',IsFixed);
        bodyFormData.append('FixedBy',values.FixedBy);
        bodyFormData.append('FixRemarks',values.FixRemarks);
        axios({
            url: global.BASE_URL+"STLCNPTProject/UpdateErrorFix",
            method: 'post',
            headers: {'Content-Type': 'multipart/form-data'},
            data: bodyFormData
        })
        .then(function (response) {
            alert("Saved Successfully.");
        })
        .catch(function (response) {
            console.log(response);
        });


    } catch (error) {
        console.log(error);
}
}
  
const styles = theme => ({
    root: {
        '& .super-app-theme--header': {
          backgroundColor: global.COLOR.Secondary,
          color: 'white',
          fontSize: 16,
        },
    },
});

class Error_Page_Edit extends React.Component {

    constructor(props) {
        super(props);
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        var date = new Date(new Date()).toLocaleDateString([],options);
        var defaulttoDate = new Date();
        this.GetErrorDetails = this.GetErrorDetails.bind(this);
        this.onClear = this.onClear.bind(this);
        this.oncheckbox = this.oncheckbox.bind(this);
        this.state = {
            ProjectGroup:'',AppVersion:'',UserName:'',ErrorDate:'',ModuleName:'',MethodName:'',ErrorMessage:'',ErrorMessageDetails:'',isCheck:'none',defaulttoDate:defaulttoDate,IsFixed:false
        };
    }

    GetErrorDetails(){
        var bodyFormData = new FormData();
        bodyFormData.append('ErrorID',this.props.ErrorID);
        axios({
            url: global.BASE_URL+"STLCNPTProject/GetErrorByID",
            method: 'post',
            headers: {'Content-Type': 'multipart/form-data' },
            data: bodyFormData
        })
         .then((response) => {
             if( response.data.dtData[0].is_fixed === true){
                 this.setState({ isCheck:'flex' });
             } else {
                this.setState({ isCheck:'none' });
             }
            this.setState({ 
                ProjectGroup: response.data.dtData[0].project_group,
                AppVersion: response.data.dtData[0].app_version,
                UserName: response.data.dtData[0].user_name,
                ErrorDate: response.data.dtData[0].error_date,
                ModuleName: response.data.dtData[0].module_name,
                MethodName: response.data.dtData[0].method_name,
                ErrorMessage: response.data.dtData[0].error_msg,
                ErrorMessageDetails: response.data.dtData[0].error_msg_details,
                IsFixed: response.data.dtData[0].is_fixed,  
                FixedBy: response.data .dtData[0].fixed_by,
                FixRemarks:response.data.dtData[0].fix_remarks

            });
        })
        .catch((response) => {
            console.log(response);
        });
    }
     
    oncheckbox(e){
        if(e.target.checked === true || e.target.value === true){
            this.setState({isCheck:'flex',IsFixed:true});
            IsFixed = 1;
        } else{
            this.setState({isCheck:'none',IsFixed:false}); 
            IsFixed = 0;
        }
    }

    componentDidMount(){
        if(this.props.ErrorID !== ""){
            ErrorID = this.props.ErrorID;
            this.GetErrorDetails();
        }
        else{
            ErrorID = "";
        }
    }
    onClear(){
        this.setState({datePickerInputDate:'',defaulttoDate:''});
    }

    render(){
        const { classes } = this.props; 
    return(
            <div>
                <Formik initialValues={{FixedBy:'',
                  FixRemarks:''
            }}   validationSchema = {validationSchema} onSubmit={onSubmit} enableReinitialize >
                {formik => {
                    return <Form>
                        <div>
                            <table>
                                <tr>
                                    
                                    <td>
                                        <label style={{width:"18%"}}>Project Group: </label><label style={{fontWeight:'bold'}}>{this.state.ProjectGroup}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label style={{width:"18%"}}>App Version: </label><label style={{fontWeight:'bold'}}>{this.state.AppVersion}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label style={{width:"18%"}}>User Name: </label><label style={{fontWeight:'bold'}}>{this.state.UserName}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label style={{width:"18%"}}>Error Date: </label><label style={{fontWeight:'bold'}}>{this.state.ErrorDate}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label style={{width:"18%"}}>Module Name: </label><label style={{fontWeight:'bold'}}>{this.state.ModuleName}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label style={{width:"18%"}}>Method Name: </label><label style={{fontWeight:'bold'}}>{this.state.MethodName}</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label style={{width:"18%"}}>Error Message: </label><label style={{fontWeight:'bold'}}>{this.state.ErrorMessage}</label>
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td>
                                        <label style={{width:"18%"}}>Error Message Details: </label><label style={{fontWeight:'bold'}}>{this.state.ErrorMessageDetails}</label>
                                    </td>
                                </tr>
                                </table>
                        </div>
                                    <BiztranControl.Checkbox style={{marginTop:16}} value={this.state.IsFixed}  onChange={e => {this.oncheckbox(e);}} label="Is Error Fixed" name="IsFixed" id="isfixed"/>
                                    
                                  
                                      
                        <div style={{display:this.state.isCheck}} > 
                            {/* <table>
                               <tr>
                                    <td>
                                        <BiztranControls_01 control='input' style={{height:35,width:"250px"}} label="Fixed By " name="FixedBy" id="fixedby" />
                                    </td>
                                    <td >
                                        <label style={{color:"grey",fontSize:12,marginLeft:20}}>Fixed On</label>
                                        <DatePickerInput style={{marginLeft:20}} onChange={(jsDate, dateString) => this.setState({datePickerInputDate2:dateString}) } locale="en" displayFormat="DD/MMM/YYYY"
                                            className='my-react-component' onClear={this.onClear} showOnInputClick maxDate={new Date()} returnFormat="DD/MMM/YYYY" defaultValue={this.state.defaulttoDate}             
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <BiztranControls_01 control='textarea'type='text'style={{height:45,width:"250px"}}  label='Fixed Remarks' name='FixedRemarks'  />  
                                </tr>
                                <BiztranControl.Button text="Save " style={{color:'white', backgroundColor:global.COLOR.Secondary, marginTop:'10px'}}  startIcon={<SaveIcon />} />
                            </table> */}
                            <Grid container spacing={2}>
                                <Grid item xs={3} style={{width:'250px'}}>
                                    <BiztranControls_01 control='input' style={{height:35,width:"250px"}}  value={this.state.FixedBy} label="Fixed By " name="FixedBy" id="fixedby" />
                                </Grid>
                                <Grid item xs={3} style={{ marginLeft:16}}>
                                    <label style={{color:"grey",fontSize:12,marginLeft:20}}>Fixed On</label>
                                    <DatePickerInput style={{marginLeft:20,width:'250px'}} onChange={(jsDate, dateString) => this.setState({datePickerInputDate2:dateString}) } locale="en" displayFormat="DD/MMM/YYYY"
                                        className='my-react-component' onClear={this.onClear} showOnInputClick maxDate={new Date()} returnFormat="DD/MMM/YYYY" defaultValue={this.state.defaulttoDate}             
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <BiztranControls_01 control='textarea'type='text'style={{height:45,width:'515px'}} value={this.state.FixRemarks} label='Fixed Remarks' name='FixRemarks'  />
                                </Grid>
                                <Grid item xs={12}>
                                    <BiztranControl.Button type="submit" text="Save " style={{color:'white', backgroundColor:global.COLOR.Secondary, marginTop:'10px'}}  startIcon={<SaveIcon />} />
                                </Grid>
                             </Grid>
                        </div> 
                                  
                                                  
                        </Form>
                    }
                }
                </Formik> 
            </div>
        );
    }
}

export default withStyles(styles)(Error_Page_Edit);
